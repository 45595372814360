import React, { useState, useEffect } from "react";

import ProjectsSection from "components/pages/ProjectsPage/ProjectsSection/ProjectsSection";
import { Helmet } from "react-helmet";

const ProjectsPage = () => {

	return (
		<div>
			<Helmet>
				<title>Projects Page</title>
				<meta name="description" content="This is the Projects Page" />
			</Helmet>
			<ProjectsSection />
		</div>
	);
};

export default ProjectsPage;
