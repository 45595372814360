import React, { useEffect } from "react";
import OverviewSection from "./OverviewSection/OverviewSection";
import ProjectBanner from "components/shared/ProjectBanner/ProjectBanner";
import ProjectNavButtons from "components/shared/ProjectsNavButtons/ProjectsNavButtons";
import { Helmet } from "react-helmet";

const RadioVectorArt = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div>
			<Helmet>
				<title>2D Realistic Radio Vector Art</title>
				<meta name="description" content="This is the 2D Realistic Radio Vector Art Page" />
			</Helmet>
			<ProjectBanner
				title="2D Realistic Radio Vector Art"
				imageSrc="/images/design-3.webp"
			/>
			<OverviewSection />
			<ProjectNavButtons prevLink="/bloom-box-logo" nextLink="/chinese-zodiac" />
		</div>
	);
};

export default RadioVectorArt;
