import React, { useEffect } from "react";
import OverviewSection from "./OverviewSection/OverviewSection";
import ProjectBanner from "components/shared/ProjectBanner/ProjectBanner";
import ProjectNavButtons from "components/shared/ProjectsNavButtons/ProjectsNavButtons";
import { Helmet } from "react-helmet";

const FurnitureBannerDesign = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div>
			<Helmet>
				<title>Furniture Banner Design</title>
				<meta
					name="description"
					content="This project showcases a collection of furniture banner designs created for a furniture store."
				/>
			</Helmet>
			<ProjectBanner
				title="Weekends Catalog &  Campaign"
				imageSrc="images/weekend-banner.webp"
			/>
			<OverviewSection />
			<ProjectNavButtons prevLink="/bloom-app-design" nextLink="/bloom-box-logo" />
		</div>
	);
};

export default FurnitureBannerDesign;
