export const projects = [
	{
		title: "Domino’s APP Redesign",
		image: "/images/feature-work-1.jpg",
		link: "/domino-app-redesign",
		description:
			"Create a user-centered, modern interface for the existing Domino's App.",
		category: "UI/UX Design",
		tools: ["Figma", "Illustrator", "Photoshop", "Google Form"],
	},
	{
		title: "Bloom App Design",
		image: "/images/feature-work-5.jpg",
		link: "/bloom-app-design",
		description:
			"Create a user-centered, modern interface for the existing Bloom App.",
		category: "UI/UX Design",
		tools: ["Figma"],
	},
	{
		title: "Weekends Catalog &  Campaign",
		image: "/images/weekend-cover.webp",
		link: "/furniture-banner-deisgn",
		description: "Design branding, catalog and campaigns for Weekends.",
		category: "Graphic Design",
		tools: ["InDesign", "Illustrator", "Photoshop"],
	},
	{
		title: "BloomBox Logo Design",
		image: "/images/bloombox-cover.webp",
		link: "/bloom-box-logo",
		description: "Create branding with email newsletter design and mockups.",
		category: "Graphic Design",
		tools: ["Illustrator", "Photoshop", "InDesign"],
	},
	{
		title: "2D Realistic Radio Vector Art",
		image: "/images/feature-work-2.jpg",
		link: "/2d-radio-vector-art",
		description:
			"Produce a photo-realistic 2D illustration that achieves a 3D effect.",
		category: "Graphic Design",
		tools: ["Illustrator"],
	},
	{
		title: "Chinese Zodiac Collection",
		image: "/images/feature-work-4.jpg",
		link: "/chinese-zodiac",
		category: "Graphic Design",
		description:
			"Reimagine the Chinese zodiacs through the traditional art of papercutting.",
		tools: ["Illustrator", "Photoshop"],
	},
	{
		title: "Cyber Era Poster Design",
		image: "/images/feature-work-6.jpg",
		link: "/cyber-era-poster",
		description: "Design a poster that represents the cyber era.",
		category: "Graphic Design",
		tools: ["Illustrator", "Photoshop"],
	},
	{
		title: "Summit | Promotional Video",
		image: "https://www.youtube.com/embed/6uoTv0i-57Q?controls=0&rel=0",
		link: "/summit-promotional-video", 
		description: "Create a promotional video for Summit Sports.",
		category: "Motion Graphic",
		tools: ["Premiere Pro", "After Effect", "Illustrator"],
	},
	{
		title: "Cupid | Lyric Video",
		image: "https://www.youtube.com/embed/yylNn0qRsiY?controls=0&rel=0",
		link: "/cupid-lyric-video",
		description: "Create a lyric video for K-pop song 'Cupid'.",
		category: "Motion Graphic",
		tools: ["After Effect", "Illustrator", "Photoshop"],
	},
];
