import React from "react";
import SelfIntrocutionSection from "./SelfIntroductionSection/SelfIntroductionSection";
import SkillSection from "./SkillSection/SkillSection";
import WorkExperienceSection from "./WorkExperienceSection/WorkExperienceSection";
import FloatingGallery from "./FloatingGallery/FloatingGallery";
import EducationSection from "./EducationSection/EducationSection";
import { Helmet } from "react-helmet";

const AboutPage = () => {
	return (
		<div>
			<div>
				<Helmet>
					<title>About Me Page</title>
					<meta name="description" content="This is the About Me Page" />
				</Helmet>
			</div>
			<SelfIntrocutionSection />
			<SkillSection />
			<WorkExperienceSection />
			<EducationSection />
			<FloatingGallery />
		</div>
	);
};

export default AboutPage;
