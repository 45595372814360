import React from "react";

import IntroductionSection from "components/pages/HomePage/IntroductionSection/IntroductionSeaction";
import FeatureWorksSection from "components/pages/HomePage/FeatureWorkSection/FeatureWorkSection";
import { Helmet } from "react-helmet";

const HomePage = () => {
    return (
        <div>
            <Helmet>
                <title>Home Page</title>
                <meta name="description" content="This is the Home Page" />
            </Helmet>
            <IntroductionSection />
            <FeatureWorksSection />
        </div>
    );
}

export default HomePage;