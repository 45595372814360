import React, { useEffect } from "react";
import OverviewSection from "./OverviewSection/OverviewSection";
import ProjectBanner from "components/shared/ProjectBanner/ProjectBanner";
import ProjectNavButtons from "components/shared/ProjectsNavButtons/ProjectsNavButtons";
import { Helmet } from "react-helmet";

const BloomBoxLogoDesign = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div>
			<Helmet>
				<title>BloomBox Logo Design</title>
				<meta name="description" content="This is the BloomBox Logo Design Page" />
			</Helmet>
			<ProjectBanner
				title="BloomBox Logo Design"
				imageSrc="images/bloombox-banner.webp"
			/>
			<OverviewSection />
			<ProjectNavButtons prevLink="/furniture-banner-deisgn" nextLink="/2d-radio-vector-art" />
		</div>
	);
};

export default BloomBoxLogoDesign;
