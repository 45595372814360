import React, { useEffect } from "react";
import OverviewSection from "./OverviewSection/OverviewSection";
import ProjectBanner from "components/shared/ProjectBanner/ProjectBanner";
import ProjectNavButtons from "components/shared/ProjectsNavButtons/ProjectsNavButtons";
import { Helmet } from "react-helmet";

const ChineseZodiac = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div>
			<Helmet>
				<title>Chinese Zodiac Illustrations</title>
				<meta
					name="description"
					content="This project showcases a collection of Chinese zodiac illustrations created using papercut art style."
				/>
			</Helmet>
			<ProjectBanner
				title="Papercut Illustrations: Chinese Zodiac Collection"
				imageSrc="images/chinese-zodiac-banner.webp"
			/>
			<OverviewSection />
			<ProjectNavButtons prevLink="/bloom-box-logo" nextLink="/cyber-era-poster" />
		</div>
	);
};

export default ChineseZodiac;
