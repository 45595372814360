import React, { useEffect } from "react";
import OverviewSection from "./OverviewSection/OverviewSection";
import ProjectBanner from "components/shared/ProjectBanner/ProjectBanner";
import ProjectNavButtons from "components/shared/ProjectsNavButtons/ProjectsNavButtons";
import { Helmet } from "react-helmet";

const BloomAppDesign = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div>
			<Helmet>
				<title>Bloom App Design</title>
				<meta name="description" content="This is the Bloom App Design Page" />
			</Helmet>
			<ProjectBanner
				title="Bloom App Design"
				imageSrc="/images/bloom-app-banner.webp"
			/>
			<OverviewSection />
			<ProjectNavButtons prevLink="/furniture-banner-deisgn" nextLink="/furniture-banner-deisgn" />
		</div>
	);
};

export default BloomAppDesign;